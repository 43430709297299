import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Grid,
  Alert as MuiAlert,
  TextField,
  Typography,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  titleStyle,
  inputLabelStyle,
  inputStyle,
  disabledInputStyle,
  datePickerStyle,
  autocompleteInputStyle,
  containerStyle,
  MuiSelectStyle,
  SelectNoneOptionStyle,
  MenuItemStyle,
  MenuItemInnerStyle,
  MenuItemDisabledStyle,
} from "../../styles/styles";
import GoBack from "../../GoBack/GoBack";


export default function ManageCase() {
  const { state } = useLocation();
  const infection = state && state.infection ? state.infection : null;
  const { getAccessTokenSilently } = useAuth0();
  const [isConfirmed, setIsConfirmed] = React.useState(infection?.isConfirmed ?? false);
  const [caseStatus, setCaseStatus] = React.useState(infection?.status.toLowerCase() ?? "open");
  const [organization, setOrganization] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [pathogens, setPathogens] = React.useState();
  const [policies, setPolicies] = React.useState();
  const [pathogen, setPathogen] = React.useState(
    infection && infection.pathogen ? infection.pathogen : null
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [infectionSite, setInfectionSite] = React.useState(
    infection && infection.infectionSite ? infection.infectionSite : null
  );
  const [infectionSites, setInfectionSites] = React.useState();
  const [symptomsStart, setSymptomsStart] = React.useState(
    infection?.symptomsStart
      ? dayjs(new Date(infection.symptomsStart))
      : dayjs(new Date()).subtract(1, "week")
  );
  const [snackbarSuccess, setSnackbarSuccess] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(
    state && snackbarSuccess ? true : false
  );
  const [wearers, setWearers] = React.useState();
  const [wearer, setWearer] = React.useState(
    infection && infection.infectedPerson ? infection.infectedPerson : null
  );
  const [wearerError, setWearerError] = React.useState();
  const [accessToken, setAccessToken] = React.useState();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    setDialogOpen(false);
  };

  const fetchPathogens = async () => {
    const pathogenData = await fetch(
      `${process.env.REACT_APP_API_URL}/pathogens`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const pathogenArray = await pathogenData.json();

    const filteredPathogens = pathogenArray.map((p) => {
      return {
        ...p,
        disabled: false,
      };
    });

    setPathogens(filteredPathogens);
  };

  const fetchPolicies = async (orgId) => {
    const pathogenData = await fetch(
      `${process.env.REACT_APP_API_URL}/pathogens/policies`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const policiesArray = await pathogenData.json();

    setPolicies(policiesArray.records);
  };

  const fetchOrganization = async () => {
    const organizationData = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/my`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const organizationDataJson = await organizationData.json();

    setOrganization(organizationDataJson.id);

    if (!policies) {
      fetchPolicies(organizationDataJson.id);
    }
  };

  const fetchInfectionSites = async () => {
    const infectionSitesData = await fetch(
      `${process.env.REACT_APP_API_URL}/pathogens/infection-sites`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const infectionSitesArray = await infectionSitesData.json();

    const filteredInfectionSites = infectionSitesArray.map((is) => {
      return {
        ...is,
        disabled: false,
      };
    });

    setInfectionSites(filteredInfectionSites);
  };

  const handlePathogenChange = (event) => {
    const pathogenName = event.target.value;

    let filteredInfectionSites;
    let filteredPolicies;

    setPathogen(event.target.value);

    if (pathogenName !== null && pathogenName !== "") {
      filteredPolicies = policies.filter((p) => {
        return p.pathogen === pathogenName;
      });

      filteredPolicies = filteredPolicies.map((p) => {
        return p.infectionSite;
      });

      filteredInfectionSites = infectionSites.map((p) => {
        return { ...p, disabled: !filteredPolicies.includes(p.code) };
      });
    } else {
      filteredInfectionSites = infectionSites.map((p) => {
        return { ...p, disabled: false };
      });
    }

    setInfectionSites(filteredInfectionSites);
  };

  const handleInfectionSiteChange = (event) => {
    const site = event.target.value;
    let filteredPathogens;
    let filteredPolicies;

    setInfectionSite(site);

    if (site !== null && site !== "") {
      filteredPolicies = policies.filter((p) => {
        return p.infectionSite === site;
      });

      filteredPolicies = filteredPolicies.map((p) => {
        return p.pathogen;
      });

      filteredPathogens = pathogens.map((p) => {
        return { ...p, disabled: !filteredPolicies.includes(p.code) };
      });
    } else {
      filteredPathogens = pathogens.map((p) => {
        return { ...p, disabled: false };
      });
    }

    setPathogens(filteredPathogens);
  };

  const fetchWearers = async () => {
    const wearerData = await fetch(`${process.env.REACT_APP_API_URL}/people`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const wearerDataJson = await wearerData.json();

    const wearerArray = wearerDataJson.records;

    setWearers(
      wearerArray.map((person) => ({
        ...person,
        label: person.fullName,
      }))
    );
  };

  const navigate = useNavigate();

  const isFormValid = () => {
    let errors = false;

    if (!wearer) {
      setWearerError(true);
      errors = true;
    } else {
      setWearerError(false);
    }

    return errors ? false : true;
  };

  const handleFormSubmit = async () => {
    try {

      if (!isFormValid()) {
        return false;
      }

      setSaving(true);

      if (infection) {
        await UpdateCase();
      } else {
        await CreateCase();
      }
    } catch (error) {
      console.log("Error:", error);
      setErrorMessage(JSON.stringify(error));
      setSnackbarOpen(true);
    }
    finally {
      setSaving(false);
    }

  };

  const UpdateCase = async () => {
    const que = {
      id: infection.id,
      pathogen: pathogen,
      personId: wearer.id,
      organizationId: organization,
      symptomsStart: symptomsStart,
      infectionSite: infectionSite,
      dateConfirmed: isConfirmed
        ? infection.dateConfirmed
          ? infection.dateConfirmed
          : new Date().toISOString()
        : null,
      dateOpened: infection.dateOpened,
      dateClosed: infection.dateClosed
        ? infection.dateClosed
        : caseStatus === "closed"
          ? new Date().toISOString()
          : null,
      dateRejected: infection.dateRejected
        ? infection.dateRejected
        : caseStatus === "rejected"
          ? new Date().toISOString()
          : null,
      status: caseStatus,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/infections/${infection.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(que),
      }
    );

    const data = await response.json();

    if (data.error) {
      setErrorMessage(JSON.stringify(data.error));
      setSnackbarOpen(true);
    } else {
      navigate("/infection-safe/consequences", {
        state: {
          snackbarSuccess: true,
          infectionId: infection.id,
          infectedPerson: data, // assuming the response is the updated infection case
        },
      });
    }
  };

  const CreateCase = async () => {
    const que = {
      pathogen: pathogen,
      personId: wearer.id,
      organizationId: organization,
      symptomsStart: symptomsStart,
      infectionSite: infectionSite,
      dateConfirmed: isConfirmed ? new Date().toISOString() : null,
      dateOpened: new Date().toISOString(),
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/infections`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(que),
    });

    const data = await response.json();

    if (data.error) {
      setErrorMessage(JSON.stringify(data.error));
      setSnackbarOpen(true);
    } else {
      const infectionId = data.id;

      const infectionData = await fetch(
        `${process.env.REACT_APP_API_URL}/infections/${infectionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const newCase = await infectionData.json();

      navigate("/infection-safe/consequences", {
        state: {
          snackbarSuccess: true,
          infectionId,
          infectedPerson: newCase,
        },
      });
    }
  };

  // For future use when we trigger a recalculation of the consequences
  const handleSaveButtonClick = (event) => {
    if (infection) {
      if (
        infection.pathogen !== pathogen ||
        infection.infectionSite !== infectionSite
      ) {
        handleDialogOpen();
        return;
      }
    }
    handleFormSubmit();
  };

  const handleWearerChange = (_event, value) => {
    setWearer(value);
  };

  const handleSymptomsStartChange = (string) => {
    setSymptomsStart(dayjs(string));
  };

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (!pathogens) {
      fetchPathogens();
    }

    if (!wearers) {
      fetchWearers();
    }

    if (!infectionSites) {
      fetchInfectionSites();
    }

    if (!organization) {
      fetchOrganization();
    }
  }, [accessToken]);

  React.useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "openid profile email",
          },
        });

        setAccessToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, []);

  return (
    <div>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          {infection ? "Update Infection Case" : "Add New Infection Case"}
        </Typography>

        {!saving && wearers && pathogens && organization && infectionSites ? (
          <>
            <Grid container>
              <Grid item xs={3.8}>
                <label for="wearer" style={inputLabelStyle}>
                  Name of infected person
                </label>
                <br />
                <Autocomplete
                  disablePortal
                  disabled={wearer ? true : false}
                  id="wearer"
                  options={wearers}
                  sx={{ color: "black" }}
                  inputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  onChange={handleWearerChange}
                  defaultValue={wearer ? wearer : null}
                  popupIcon={
                    <KeyboardArrowDownIcon
                      style={{ color: "rgb(128 133 147)", fontSize: "19px" }}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ ...autocompleteInputStyle, color: "black" }}
                    />
                  )}
                />

                {wearerError ? (
                  <FormHelperText error={true}>
                    Infected person cannot be blank.
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={3.8}>
                <label for="status" style={inputLabelStyle}>
                  Role
                </label>
                <br />
                <>
                  <select
                    id="status"
                    readonly
                    disabled
                    aria-disabled="true"
                    aria-readonly="true"
                    value={wearer ? wearer.role : ""}
                    name="status"
                    style={disabledInputStyle}
                  >
                    <option value={wearer ? wearer.role : ""}>
                      {wearer ? wearer.role : ""}
                    </option>
                  </select>
                </>
              </Grid>
              <Grid item xs={2.5}>
                <label for="date" style={inputLabelStyle}>
                  Date & time of symptom onset
                </label>
                <br />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en_GB"
                  localeText={{
                    okButtonLabel: "Save",
                    todayButtonLabel: "Set date & time to now",
                  }}
                >
                  <DateTimePicker
                    sx={{ ...datePickerStyle, maxWidth: "100%", width: "100%" }}
                    format="DD-MM-YYYY h:mm A"
                    slotProps={{
                      actionBar: { actions: ["today", "accept"] },
                    }}
                    value={symptomsStart}
                    onChange={handleSymptomsStartChange}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                  {/* 
                  <DateTimePicker
                    orientation="landscape"
                    value={dateTime}
                    onChange={handleDateTimeChange}
                  /> */}
                </LocalizationProvider>
              </Grid>
            </Grid>

            <div
              className="horizontal-line"
              style={{ margin: "28px 0px 28px 0px" }}
            ></div>

            <Grid container>
              <Grid item xs={3.8}>
                <label
                  id="infection-site-label"
                  for="infectionSite"
                  style={inputLabelStyle}
                >
                  Infection site
                </label>
                <br />
                <Select
                  labelId="infection-site-label"
                  id="infectionSite"
                  style={MuiSelectStyle}
                  value={infectionSite}
                  onChange={handleInfectionSiteChange}
                  disabled={
                    infection && infectionSite && pathogen ? true : false
                  }
                >
                  <MenuItem style={MenuItemStyle} value="">
                    <em style={SelectNoneOptionStyle}>None</em>
                  </MenuItem>
                  {infectionSites ? (
                    infectionSites.map((is) => {
                      return (
                        <MenuItem value={is.code} style={MenuItemStyle}>
                          {!is.disabled ? (
                            <span style={MenuItemInnerStyle}>{is.name}</span>
                          ) : (
                            <button
                              style={MenuItemDisabledStyle}
                              onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                return false;
                              }}
                            >
                              {is.name}
                            </button>
                          )}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Select>
              </Grid>

              <Grid item xs={3.8}>
                <div style={{ paddingTop: "24px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="confirmed"
                          onChange={(e) => setIsConfirmed(e.target.checked)}
                          checked={isConfirmed}
                        />
                      }
                      label="Confirmed"
                    />
                  </FormGroup>
                </div>
              </Grid>

              <Grid item xs={2.5}>
                {infection && (
                  <>
                    <label
                      id="status-label"
                      for="status"
                      style={inputLabelStyle}
                    >
                      Status
                    </label>
                    <br />
                    <Select
                      labelId="status-label"
                      id="status"
                      style={MuiSelectStyle}
                      value={caseStatus}
                      onChange={(e) => setCaseStatus(e.target.value)}
                    >
                      <MenuItem style={MenuItemStyle} value="open">Open</MenuItem>
                      <MenuItem style={MenuItemStyle} value="closed">Closed</MenuItem>
                      <MenuItem style={MenuItemStyle} value="rejected">Withdrawn</MenuItem>

                    </Select>
                  </>
                )}
              </Grid>

            </Grid>

            <div
              className="horizontal-line"
              style={{ margin: "28px 0px 28px 0px" }}
            ></div>

            <Grid container>
              <Grid item xs={3.8}>
                <label
                  id="pathogen-label"
                  for="pathogen"
                  style={inputLabelStyle}
                >
                  Pathogen or disease
                </label>
                <br />
                <Select
                  labelId="pathogen-label"
                  id="pathogen"
                  style={MuiSelectStyle}
                  value={pathogen}
                  onChange={handlePathogenChange}
                  disabled={
                    infection && pathogen && infectionSite ? true : false
                  }
                >
                  <MenuItem style={MenuItemStyle} value="">
                    <em style={SelectNoneOptionStyle}>None</em>
                  </MenuItem>
                  {pathogens ? (
                    pathogens.map((p) => (
                      <MenuItem value={p.code} style={MenuItemStyle}>
                        {!p.disabled ? (
                          <span style={MenuItemInnerStyle}>{p.name}</span>
                        ) : (
                          <button
                            style={MenuItemDisabledStyle}
                            onClick={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              return false;
                            }}
                          >
                            {p.name}
                          </button>
                        )}
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </Grid>
            </Grid>

            <div
              style={{
                width: "100%",
                marginTop: "97px",
                marginBottom: "97px",
              }}
            >
              <button
                style={{
                  background: "#01C1D3 0% 0% no-repeat padding-box",
                  borderRadius: "8px",
                  fontSize: "13px",
                  padding: "13px 44px 18px 44px",
                  borderWidth: "0px",
                  color: "white",
                  display: "block",
                  margin: "auto",
                  cursor: "pointer",
                }}
                onClick={handleFormSubmit}
              >
                {infection ? "Update case" : "Save case and show consequences"}
              </button>
            </div>
          </>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "100px",
                }}
              >
                <CircularProgress />
              </div>
            </Grid>
          </Grid>
        )}
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-confirm-title"
        aria-describedby="alert-confirm-description"
      >
        <DialogTitle id="alert-confirm-title">
          {"Confirm policy change"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-confirm-description">
            Changing the confirmation status will result in a policy change. Are
            you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant={"contained"} onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarSuccess ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Saved successfully
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Error: {errorMessage}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
