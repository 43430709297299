import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Grid } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "./App.css";
import MainContainer from "./components/MainContainer/MainContainer";

import Login from "./components/Login/Login";

let context = {
  kind: "category",
  key: "anonymous",
  name: "anonymous",
};

if (
  window.location.href.indexOf("carehome.canary.proxximos.com") > -1 ||
  window.location.href.indexOf("localhost") > -1
) {
  context = {
    kind: "category",
    key: "carehome",
    name: "carehome",
  };
} else {
  context = {
    kind: "category",
    key: "nhs",
    name: "nhs",
  };
}

function App() {
  const [orgId, setOrgId] = React.useState();
  const [currentLocation, setCurrentLocation] = React.useState();
  const [sites, setSites] = React.useState();
  const [tags, setTags] = React.useState();
  const [accessToken, setAccessToken] = React.useState();
  const fetchingSites = React.useRef();
  const fetchingTags = React.useRef();
  const [cookies, setCookie] = useCookies(["defaultSite"]);
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();

  const [logoUrl, setLogoUrl] = useState(null);
  const [middleNavbarImage, setMiddleNavbarImage] = useState(null);

  const handleSignout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const fetchTags = async () => {
    if (accessToken && !fetchingTags.current) {
      fetchingTags.current = true;

      const tagsData = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const tagsJson = await tagsData?.json();

      const tags = tagsJson?.records;

      setTags(
        tags.map((tag) => ({
          wearableId: tag.electronicsId,
          serial: tag.serial,
          person: tag.assignedPerson?.fullName,
        }))
      );

      fetchingTags.current = false;
    }
  };

  const fetchSites = async () => {
    fetchingSites.current = true;

    const sitesData = await fetch(`${process.env.REACT_APP_API_URL}/sites`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const sitesList = await sitesData?.json();

    setSites(sitesList);

    setCurrentLocation(
      cookies.defaultSite ? cookies.defaultSite : sitesList[0]
    );
  };

  const getOrgId = async () => {
    if (accessToken && !orgId) {
      try {
        const organization = await fetch(
          `${process.env.REACT_APP_API_URL}/organizations/my`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const organizationJson = await organization.json();

        setOrgId(organizationJson.id);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
          scope: "openid profile email",
        },
      });

      setAccessToken(token);
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  };

  // React.useEffect(() => {
  //   if (auth0User && !mergedUser && isAuthenticated) {
  //     getUser(auth0User);
  //   }
  // }, [isAuthenticated, auth0User]);

  React.useEffect(() => {
    getOrgId();
  }, [accessToken]);

  // React.useEffect(() => {
  //   if (mergedUser && isAuthenticated) {
  //     setLogoUrl(
  //       mergedUser.isCareHome
  //         ? `${process.env.REACT_APP_S3_URL}/proxximos_safer_care.png`
  //         : `${process.env.REACT_APP_S3_URL}/nhs_logo.png`
  //     );
  //     setMiddleNavbarImage(
  //       `${process.env.REACT_APP_S3_URL}/client_logos/${mergedUser.clientId}/logo.png` // Add cachebuster to force refresh
  //     );
  //   }
  // }, [mergedUser, isAuthenticated]);

  React.useEffect(() => {
    // Once the user has been fetched and merged with the user object from the RTLS API, fetch the list of sites
    // This runs when the token is set or whenever the mergedUser is set
    if (accessToken && !sites && !fetchingSites.current) {
      fetchSites();
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (!tags) {
      fetchTags();
    }
  }, [accessToken]);

  React.useEffect(() => {
    fetchToken();
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    fetchToken();
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Login />

        {isAuthenticated && orgId && accessToken ? (
          <div id="frame" className={"wrapper"}>
            <MainContainer
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              sites={sites}
              tags={tags}
              refreshTags={fetchTags}
              signOut={handleSignout}
              logoUrl={logoUrl}
              middleNavbarImage={middleNavbarImage}
            ></MainContainer>
          </div>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "100px",
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            </Grid>{" "}
          </>
        )}
      </LocalizationProvider>
    </>
  );
}

export default App;
