import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Document } from "@react-pdf/renderer";
import CancelIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DialogTitle from "@mui/material/DialogTitle";
import MuiAlert from "@mui/material/Alert";
import { usePDF } from "@react-pdf/renderer";
import reactElementToJSXString from "react-element-to-jsx-string";
import { saveAs } from "file-saver";
import { Download as DownloadIcon } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  DataGrid,
  useGridApiRef,
} from "@mui/x-data-grid";
import "./style.css";
import {
  titleStyle,
  mainContainerNavStyle,
  buttonStyle,
  cyanBg,
  lightGrayBg,
  lightGrayBgDisabled,
  navyBg,
  buttonGroupContainerStyle,
  downloadIconContainerStyle,
  downloadIconStyle,
  buttonJustifiedRightStyle,
  downloadContainerStyle,
  downloadLinkText,
  buttonTextStyle,
  buttonIconStyle,
  addIconStyle,
  searchWrapperButtonStyle,
  scrollableTableStyle,
  containerStyle,
} from "../../styles/styles";
import AddIcon from "../../AddIcon/AddIcon";
import SearchIcon from "../../SearchIcon/SearchIcon";
import FormattedDate from "../../FormattedDate/FormattedDate";
import ICSReportPdf from "../../PdfTemplates/InfectionControlSummaryPdf";
import { CircularProgress } from "@mui/material";
import CaseStatusSwitcher from "../../cases/CaseStatusSwitcher";

const searchWrapperStyle = {
  height: 38,
  fontSize: 12,
  fontWeight: 500,
  color: "#333333",
  padding: "9px 95px 11px 20px",
  display: "inline-flex",
  justifyContent: "space-between",
  position: "relative",
};

const searchWrapperInputStyle = {
  padding: 0,
};

const searchWrapperButtonContainerStyle = {
  position: "absolute",
  right: 19,
  top: 7,
  padding: 0,
};

export default function InfectionControlSummary({ user, pdfHeaderImage }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [viewActionsDisabled, setViewActionDisabled] = React.useState(true);
  const [pathogens, setPathogens] = React.useState();
  const [currentRow, setCurrentRow] = React.useState();
  const [visibleData, setVisibleData] = React.useState();
  const [needsRefresh, setNeedsRefresh] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [snackbarSuccess, setSnackbarSuccess] = React.useState(
    state && state.snackbarSuccess ? true : false
  );
  const [snackbarOpen, setSnackbarOpen] = React.useState(
    state && snackbarSuccess ? true : false
  );
  const [data, setData] = React.useState();
  const [searchQuery, setSearchQuery] = React.useState();
  const [contactsDialogOpen, setContactsDialogOpen] = React.useState(false);
  const [currentExposureContactList, setCurrentExposureContactList] =
    React.useState();
  const apiRef = useGridApiRef();
  const [instance, updatePDF] = usePDF(<Document></Document>);
  const [accessToken, setAccessToken] = React.useState();
  const { getAccessTokenSilently } = useAuth0();

  const SUMMARY_COLS = [
    {
      field: "id",
    },
    {
      field: "fullName",
      headerName: "Case name",
      minWidth: 150,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "block" }}>
              <div>
                {params.row.infectedPerson
                  ? params.row.infectedPerson.fullName
                  : "Unassigned tag"}
              </div>
            </div>
          </>
        );
      },
    },

    { field: "infectionId" },
    {
      field: "role",
      headerName: "Role",
      minWidth: 110,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return params.row.infectedPerson ? params.row.infectedPerson.role : "";
      },
    },
    {
      field: "status",
      headerName: "Case status",
      minWidth: 150,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <CaseStatusSwitcher initialStatus={params.value} caseId={params.row.id} />;
      }
    },
    {
      field: "symptomsStart",
      headerName: "Onset date & time",
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div style={{ wordWrap: "break-word" }}>
            {moment(new Date(params.value)).format("ddd, ll")}
            <br />
            {moment(new Date(params.value)).format("HH:mm A")}
          </div>
        );
      },
    },
    {
      field: "pathogen",
      headerName: "Pathogen & type",
      minWidth: 180,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>
            {params.value}{" "}
            {params.row.infectionSite ? `(${params.row.infectionSite})` : ""}
          </div>
        );
      },
    },
    {
      field: "dateConfirmed",
      headerName: "Infection status",
      minWidth: 170,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <div>{params.value ? "Confirmed" : "Suspected"}</div>;
      },
    },
    {
      field: "contactCount",
      headerName: "No. contacts",
      minWidth: 150,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            {parseInt(params.value) > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "top",
                  flexWrap: "wrap",
                  mt: 1.5,
                  marginTop: "10px",
                }}
              >
                <Link
                  to={"/infection-safe/consequences"}
                  state={{
                    infectionId: params.row.id,
                    infectedPerson: {
                      ...params.row,
                      pathogenName: params.row.pathogen,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1.5,
                      color: "#2800D0",
                      textDecoration: "underline",
                      marginLeft: "8px",
                      fontWeight: 600,
                      fontSize: 12,
                      fontFamily: "Open Sans",
                    }}
                  >
                    {params.value} {params.value > 1 ? "contacts" : "contact"}
                  </Typography>
                </Link>
              </div>
            ) : (
              <>0</>
            )}
          </>
        );
      },
    },
    {
      field: "contaminatedRooms",
      headerName: "No. touches",
      editable: false,
      minWidth: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>
            {params.value || params.value === 0 ? params.value : "Unknown"}
          </div>
        );
      },
    },
    {
      field: "editableActions",
      type: "actions",
      headerName: "",
      cellClassName: "editable-actions",
      align: "left",
      headerAlign: "left",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => handleSaveClick(row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            // onClick={() => handleEditClick(id)}
            onClick={() => handleEditClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleContactsDialogClose = () => {
    setContactsDialogOpen(false);
  };

  const handleContactsDialogOpen = (contacts) => {
    setCurrentExposureContactList(contacts);
    setContactsDialogOpen(true);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAddNewCase = () => {
    navigate("/infection-safe/manage-case");
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const fetchInfections = async () => {
    const infectionsData = await fetch(
      `${process.env.REACT_APP_API_URL}/infections`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const infectionsDataJson = await infectionsData.json();
    const infectionsArray = infectionsDataJson.records;

    const filteredInfectionCases = infectionsArray.filter((row) => {
      if (!row.infectedPerson) {
        return false;
      }

      const oneMonthAgo = moment(new Date()).subtract(1, "months").format();
      const oneMonthAgoTimestamp = new Date(oneMonthAgo).getTime();
      const onsetTimestamp = new Date(row.dateOpened).getTime();
      if (onsetTimestamp >= oneMonthAgoTimestamp) {
        return row;
      }
      return false;
    });

    setData(filteredInfectionCases);
    setVisibleData(filteredInfectionCases);

    setNeedsRefresh(false);
  };

  const handleSearchQueryChange = (evt) => {
    const q = evt.target.value.toLowerCase();
    setSearchQuery(q);
    if (q === "") {
      setVisibleData(data);
      return;
    }
    const newData = data.filter(
      (item) =>
        item.firstName.toLowerCase().indexOf(q) > -1 ||
        item.lastName.toLowerCase().indexOf(q) > -1
    );
    setVisibleData(newData);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const newInfections = data.map((row) => {
      if (row.id === newRow.id) {
        return updatedRow;
      } else {
        return row;
      }
    });
    setData(newInfections);
    handleSave(newRow);

    return updatedRow;
  };

  const renderPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const filteredData = data.filter((row) => {
      const twoWeeksAgo = moment(new Date()).subtract(14, "days").format();
      const twoWeeksAgoTimestamp = new Date(twoWeeksAgo).getTime();
      const onsetTimestamp = new Date(row.onsetTime).getTime();
      if (onsetTimestamp >= twoWeeksAgoTimestamp) {
        return row;
      }
      return false;
    });

    const pdf = ICSReportPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      filteredData,
      pathogens,
      reportId
    );

    updatePDF(pdf);

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICS-${moment(new Date()).format("LLL")}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });
  };

  React.useEffect(() => {
    if (instance && instance.blob) {
      saveAs(
        instance.blob,
        `Proxximos-ICS-${moment(new Date()).format("LLL")}.pdf`
      );
    }
  }, [instance]);

  const handleSave = async (infection) => {
    try {
      const que = {
        ...infection,
        infectionId: infection.id,
        actions: JSON.stringify(infection.actions),
      };

      const response = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/infection`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(que),
        }
      );
      const data = await response.json();

      if (data.error) {
        setSnackbarSuccess(false);
        setErrorMessage(data.error.code);
        setSnackbarOpen(true);
      } else {
        setSnackbarSuccess(true);
        setSnackbarOpen(true);
        setNeedsRefresh(true);
      }
    } catch (error) {
      console.log("Error:", error);
      setSnackbarSuccess(false);
      setErrorMessage(error);
      setSnackbarOpen(true);
    }
  };

  const handleRowClick = (props) => {
    setCurrentRow(props.id);
    setViewActionDisabled(false);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (infection) => {
    setCurrentRow(null);
    setViewActionDisabled(true);
    setRowModesModel({
      ...rowModesModel,
      [infection.id]: { mode: GridRowModes.View },
    });
  };

  const handleViewActions = () => {
    setRowModesModel({
      ...rowModesModel,
      [currentRow]: { mode: GridRowModes.Edit },
    });
  };

  const handleEditClick = (infection) => {
    navigate("/infection-safe/manage-case", {
      state: {
        user,
        infection: {
          ...infection,
          infectedPerson: infection.infectedPerson
            ? {
                ...infection.infectedPerson,
                label: infection.infectedPerson.fullName,
              }
            : null,
        },
      },
    });
  };

  const handleCancelClick = (id) => {
    setCurrentRow(null);
    setViewActionDisabled(true);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = data.find((row) => row.id === id);
    if (editedRow.isNew) {
      setData(data.filter((row) => row.id !== id));
    }
  };

  React.useEffect(() => {
    if ((!data || needsRefresh) && accessToken) {
      fetchInfections();
    }
  }, [accessToken]);

  React.useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "openid profile email",
          },
        });

        setAccessToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, []);

  return (
    <div>
      <Box style={containerStyle}>
        <FormattedDate />

        <div style={mainContainerNavStyle}>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              lg={"auto"}
              display={{ lg: "inline-flex" }}
            >
              <Typography variant="h5" component="div" sx={titleStyle}>
                Infection Control Summary
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              xl={"auto"}
              display={{ xl: "inline-flex" }}
            >
              <div
                className="button-group-container"
                style={buttonGroupContainerStyle}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={12} md={"auto"}>
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={"auto"}>
                          <div style={buttonJustifiedRightStyle}>
                            <button
                              className="add-new-infection-button"
                              style={{
                                ...buttonStyle,
                                ...cyanBg,
                                width: "100%",
                                justifyContent: "center",
                                display: "inline-flex",
                              }}
                              onClick={handleAddNewCase}
                            >
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "top",
                                  flexWrap: "wrap",
                                }}
                              >
                                <AddIcon
                                  style={{
                                    ...buttonTextStyle,
                                    ...buttonIconStyle,
                                    ...addIconStyle,
                                  }}
                                />
                                <Typography sx={buttonTextStyle}>
                                  Add new infection case
                                </Typography>
                              </div>
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"}>
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={"auto"}>
                          <div
                            class="search-wrapper"
                            style={{ ...searchWrapperStyle, width: "100%" }}
                          >
                            <input
                              placeholder="Search this page.."
                              type="text"
                              value={searchQuery}
                              style={searchWrapperInputStyle}
                              onChange={handleSearchQueryChange}
                            />
                            <button style={searchWrapperButtonContainerStyle}>
                              <SearchIcon style={searchWrapperButtonStyle} />
                            </button>
                          </div>
                        </Grid>

                        <Grid item xs={12} md={"auto"}>
                          <Button onClick={renderPdf}>
                            {" "}
                            <div style={downloadContainerStyle}>
                              <Typography sx={downloadLinkText}>
                                Download report
                              </Typography>
                              <div
                                className="icon-container"
                                style={downloadIconContainerStyle}
                              >
                                <DownloadIcon
                                  className="download-icon"
                                  style={downloadIconStyle}
                                />
                              </div>
                            </div>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        <br></br>

        {data ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={scrollableTableStyle}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{ width: "100%" }}>
                      <DataGrid
                        apiRef={apiRef}
                        sx={{ backgroundColor: "#fff" }}
                        initialState={{
                          sorting: {
                            sortModel: [
                              { field: "symptomsStart", sort: "desc" },
                            ],
                          },
                        }}
                        columnVisibilityModel={{
                          id: false,
                          lastName: false,
                          infectionId: false,
                        }}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowClick}
                        rowHeight={100}
                        experimentalFeatures={{
                          ariaV7: true,
                          newEditingApi: true,
                        }}
                        columns={SUMMARY_COLS}
                        rows={visibleData}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={(props) => {
                          return processRowUpdate({ ...props });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "100px",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Box>
      <Dialog
        open={contactsDialogOpen}
        onClose={handleContactsDialogClose}
        aria-labelledby="list-of-contacts"
        aria-describedby="list-of-contacts"
      >
        <DialogTitle id="list-of-contacts-title">List of Contacts</DialogTitle>
        <DialogContent>
          <DialogContentText id="list-of-contacts-body">
            {currentExposureContactList ? (
              currentExposureContactList.map((contact) => {
                return (
                  <div>
                    {contact.first_name} {contact.last_name}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactsDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarSuccess ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Saved successfully
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Error: {errorMessage}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
