import React from "react";
import FloorplanSvg from "../../../FloorplanSvg/FloorplanSvg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DeadBatteryIcon from "../../../DeadBatteryIcon/DeadBatteryIcon.tsx";
import styles from "../../../../App.css";
import PanZoom from "react-easy-panzoom";
import PersonIcon from "../../../people/PersonIcon.jsx";

import ZoomInIcon from "../../../ZoomInIcon/ZoomInIcon";
import ZoomOutIcon from "../../../ZoomOutIcon/ZoomOutIcon";

const zoomIconStyle_def = {
  color: "#283555",
  fontSize: 31,
  background: "transparent",
  margin: 0,
  padding: 0,
};

const zoomIconContainerStyle_def = {
  position: "relative",
  width: 50,
  height: 50,
  padding: 10,
  backgroundColor: "#eaf0ff",
  marginTop: 25,
  marginRight: 27,
  display: "block",
  border: "none ",
  cursor: "pointer",
};

const zoomPositionAbsoluteValues = {
  tl: {
    left: 30,
    top: 15,
  },
  br: {
    right: "-17px",
    bottom: 59,
  },
  tr: {
    right: "-17px",
    top: 59,
  },
};

const zoomPositionStyle_def = {
  position: "absolute",
  display: "block",
  zIndex: 5,
};

const mapIconListStyle_def = {
  position: "absolute",
  display: "inline-flex",
  flexDirection: "column",
  zIndex: 5,
  top: 25,
  left: 40,
};

function MapTab({
  zoomIconContainerStyle = zoomIconContainerStyle_def,
  zoomPositionStyle = zoomPositionStyle_def,
  zoomIconStyle = zoomIconStyle_def,
  mapIconListStyle = mapIconListStyle_def,
  iconBackgroundColor = "#4DB735",
  iconStyle,
  type,
  bgColor = "white",
  controlsPosition = "tl",
  data,
  mapIconMetadata,
  user,
  currentContact,
  seekTime,
  lastFrameSteps,
  focusedPerson,
  width,
  height,
  contactReplayData,
  currentEncounter,
  caseReplayData,
  currentLocation,
  map,
}) {
  const [markers, setMarkers] = React.useState();
  const [openTooltip, setOpenTooltip] = React.useState();
  const [scaledHeight, setScaledHeight] = React.useState(1206);
  const [scaleFactor, setScaleFactor] = React.useState(4.23441397);
  const [scaledWidth, setScaledWidth] = React.useState(490);
  const [mapXOffset, setMapXOffset] = React.useState(0);
  const [mapYOffset, setMapYOffset] = React.useState(0);
  const focusedPersonX = React.useRef();
  const focusedPersonY = React.useRef();
  const svgRef = React.useRef();
  const panZoom = React.useRef();
  const mapContainerRef = React.useRef();

  const MAP_CONTAINER_HEIGHT = "660px";

  const handleMapXOffset = (value) => {
    setMapXOffset(value);
  };

  const handleMapYOffset = (value) => {
    setMapYOffset(value);
  };

  const handleHeightChange = (newValue) => {
    setScaledHeight(newValue);
  };

  const handleWidthChange = (newValue) => {
    setScaledWidth(newValue);
  };

  const handleScaleFactorChange = (newValue) => {
    setScaleFactor(newValue);
  };

  const mapContainerStyle = {
    backgroundColor: bgColor,
    padding: 0,
    borderRadius: "8px",
    overflow: "hidden",
  };

  zoomPositionStyle = {
    ...zoomPositionStyle,
    ...zoomPositionAbsoluteValues[controlsPosition],
  };

  const handleOpenTooltip = (id) => {
    if (openTooltip !== id) {
      setOpenTooltip(id);
    }
  };

  const HtmlTooltip = styled(({ className, id, ...props }) => {
    return (
      <Tooltip
        {...props}
        arrow
        open={openTooltip === id}
        classes={{ popper: className }}
        TransitionProps={{ timeout: 0 }}
      />
    );
  })(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: "#283555",
        border: "none",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#283555",
      color: "#fff",
      borderRadius: "6px",
      fontFamily: "Open Sans",
      minWidth: "160px",
      fontSize: 12,
      fontWeight: 700,
      padding: "16px 20px",
      maxWidth: 220,
      border: "none",
    },
  }));

  const mapIconStatic = (index, beaconId) => {
    return (
      <>
        {(mapIconMetadata[beaconId]["type"] &&
          mapIconMetadata[beaconId]["type"] === "asset") ||
        type === "asset" ? (
          <div
            style={{
              background: mapIconMetadata[beaconId].color,
              borderRadius: "100%",
              width: "21px",
              height: "21px",
            }}
          ></div>
        ) : (
          <PersonIcon
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={data[index].x * 100}
            posY={data[index].y * 100}
            style={{
              color: "#fff",
              backgroundColor: mapIconMetadata[beaconId].color,
              width: "34px",
              height: "34px",
              marginTop: "-5px",
              marginLeft: "1px",
            }}
          />
        )}
      </>
    );
  };

  const replayMapIcon = (positionsArray, serial, index) => {
    const currentContactOnsetTime = new Date(
      `${currentContact.encounters[currentEncounter].start}`
    ).getTime();

    const indexCasePersonSerialMatch =
      serial === currentContact.encounters[currentEncounter].sourceTagSerial;

    const contactPersonSerialMatch =
      serial === currentContact.encounters[currentEncounter].contactTagSerial;

    const infectionOccurred =
      new Date(`${positionsArray[index].timestamp}`).getTime() >=
        currentContactOnsetTime && contactPersonSerialMatch;

    if (indexCasePersonSerialMatch) {
      console.log("index case person icon");
      return (
        <>
          {lastFrameSteps.current === index ? (
            // index case person icon
            <PersonIcon
              setOpenTooltip={(id) => handleOpenTooltip(id)}
              id={`bug-${index}`}
              key={`bug-${index}`}
              posX={
                // scaledWidth -
                (scaleFactor
                  ? (positionsArray[index].x * 100) / scaleFactor
                  : positionsArray[index].x * 100) + "px"
              }
              posY={
                scaledHeight -
                (scaleFactor
                  ? (positionsArray[index].y * 100) / scaleFactor
                  : positionsArray[index].y * 100) +
                "px"
              }
              style={{
                color: "#fff",
                width: "110px",
                height: "110px",
                backgroundColor: "#E326DC",
              }}
            />
          ) : (
            <div
              id={`bug-${index}`}
              key={`bug-${index}`}
              onMouseEnter={() => {
                setOpenTooltip(`bug-${index}`);
              }}
              onMouseLeave={() => {
                setOpenTooltip(null);
              }}
              style={{
                width: "45px",
                height: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#e572ef",
                  width: 20,
                  height: 20,
                }}
              ></div>
            </div>
          )}
        </>
      );
    }

    if (infectionOccurred && contactPersonSerialMatch) {
      console.log("contact person icon");
      // contact person icon

      // this code block ensures that the map is always centered on the focused person
      if (focusedPerson) {
        const currentTransform =
          panZoom.current.container.current.firstChild.style.transform;

        focusedPersonX.current =
          // scaledWidth -
          scaleFactor
            ? (positionsArray[focusedPerson].x * 100) / scaleFactor
            : positionsArray[focusedPerson].x * 100;
        focusedPersonY.current =
          scaledHeight -
          (scaleFactor
            ? (positionsArray[focusedPerson].y * 100) / scaleFactor
            : positionsArray[focusedPerson].y * 100);

        // width = map container width
        // height = map container height
        const updatedTransform = currentTransform.replace(
          /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),[^,]+,[^,]+\)/,
          `matrix($1,$2,$3,$4,${-focusedPersonX.current + width / 2},${
            -focusedPersonY.current + height / 2
          })`
        );

        // Apply the updated transform back to the element
        panZoom.current.container.current.firstChild.style.transform =
          updatedTransform;
      }

      return (
        <>
          {lastFrameSteps.current === index ? (
            <PersonIcon
              setOpenTooltip={(id) => handleOpenTooltip(id)}
              id={`bug-${index}`}
              key={`bug-${index}`}
              posX={
                // scaledWidth -
                (scaleFactor
                  ? (positionsArray[index].x * 100) / scaleFactor
                  : positionsArray[index].x * 100) + "px"
              }
              posY={
                scaledHeight -
                (scaleFactor
                  ? (positionsArray[index].y * 100) / scaleFactor
                  : positionsArray[index].y * 100) +
                "px"
              }
              style={{
                color: "#fff",
                width: "40px",
                height: "45px",
                backgroundColor: "#FF892D",
              }}
            />
          ) : (
            <div
              id={`bug-${index}`}
              key={`bug-${index}`}
              onMouseEnter={() => {
                setOpenTooltip(`bug-${index}`);
              }}
              onMouseLeave={() => {
                setOpenTooltip(null);
              }}
              style={{
                width: "40px",
                height: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#ffb65e",
                  width: 20,
                  height: 20,
                }}
              ></div>
            </div>
          )}
        </>
      );
    }

    if (!infectionOccurred && contactPersonSerialMatch) {
      console.log("un-infected contact person icon");
      // un-infected contact person icon
      return (
        <>
          {lastFrameSteps.current === index ? (
            <PersonIcon
              setOpenTooltip={(id) => handleOpenTooltip(id)}
              id={`bug-${index}`}
              key={`bug-${index}`}
              posX={
                // scaledWidth -
                (scaleFactor
                  ? (positionsArray[index].x * 100) / scaleFactor
                  : positionsArray[index].x * 100) + "px"
              }
              posY={
                scaledHeight -
                (scaleFactor
                  ? (positionsArray[index].y * 100) / scaleFactor
                  : positionsArray[index].y * 100) +
                "px"
              }
              style={{
                color: "#fff",
                backgroundColor: "#FF892D",
              }}
            />
          ) : (
            <div
              id={`bug-${index}`}
              key={`bug-${index}`}
              onMouseEnter={() => {
                setOpenTooltip(`bug-${index}`);
              }}
              onMouseLeave={() => {
                setOpenTooltip(null);
              }}
              style={{
                width: "40px",
                height: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#ffb65e",
                  width: 20,
                  height: 20,
                }}
              ></div>
            </div>
          )}
        </>
      );
    }
  };

  const mapIcon = (index, beaconId) => {
    const currentDate = new Date(
      new Date().toLocaleString("en", { timeZone: "Europe/London" })
    );
    const mapIconLastUpdatedDate = new Date(
      data[index].lastLocationTime
    ).getTime();
    if (currentDate - mapIconLastUpdatedDate > 30000) {
      return (
        <div
          style={{
            background:
              mapIconMetadata && mapIconMetadata[beaconId]
                ? mapIconMetadata[beaconId].color
                : "transparent",
            borderRadius: "100%",
            width: "90px",
            height: "90px",
            paddingLeft: "13px",
          }}
        >
          <DeadBatteryIcon
            setOpenTooltip={(id) => handleOpenTooltip(id)}
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={data[index].x * 100}
            posY={data[index].y * 100}
            fill={"#000000"}
            style={{
              color:
                mapIconMetadata && mapIconMetadata[beaconId] ? "#fff" : "#000",
              width: "60px",
              height: "64px",
            }}
          />
        </div>
      );
    }
    return (
      <>
        {(mapIconMetadata &&
          mapIconMetadata[beaconId]["type"] &&
          mapIconMetadata[beaconId]["type"] === "asset") ||
        type === "asset" ? (
          <div
            style={{
              background:
                mapIconMetadata && mapIconMetadata[beaconId]
                  ? mapIconMetadata[beaconId].color
                  : iconBackgroundColor,
              borderRadius: "100%",
              width: "31px",
              height: "31px",
              ...(iconStyle ? iconStyle : {}),
            }}
          ></div>
        ) : (
          <PersonIcon
            setOpenTooltip={(id) => handleOpenTooltip(id)}
            id={`map-icon-${index}`}
            key={`map-icon-${index}`}
            posX={data[index].x * 100}
            posY={data[index].y * 100}
            style={{
              color: "#fff",
              backgroundColor:
                mapIconMetadata && mapIconMetadata[beaconId]
                  ? mapIconMetadata[beaconId].color
                  : iconBackgroundColor,
              width: "44px",
              height: "44px",
              marginTop: "-6px",
              marginLeft: "2px",
            }}
          />
        )}
      </>
    );
  };

  function onZoomIn() {
    const currentTransform =
      panZoom.current.container.current.firstChild.style.transform;

    // Regex pattern to capture all 6 arguments of the matrix()
    const updatedTransform = currentTransform.replace(
      /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+),([^,]+)\)/,
      (match, a, b, c, d, e, f) => {
        return `matrix(${parseFloat(a) + 0.3},${b},${c},${
          parseFloat(d) + 0.3
        },${e},${f})`;
      }
    );

    // Apply the updated transform back to the element
    panZoom.current.container.current.firstChild.style.transform =
      updatedTransform;
  }

  function onZoomOut() {
    const currentTransform =
      panZoom.current.container.current.firstChild.style.transform;

    // Regex pattern to capture all 6 arguments of the matrix()
    const updatedTransform = currentTransform.replace(
      /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+),([^,]+)\)/,
      (match, a, b, c, d, e, f) => {
        if (parseFloat(a) - 0.3 <= 0 || parseFloat(d) - 0.3 <= 0) {
          return `matrix(1,${b},${c},1,${e},${f})`;
        }
        return `matrix(${parseFloat(a) - 0.3},${b},${c},${
          parseFloat(d) - 0.3
        },${e},${f})`;
      }
    );

    // Apply the updated transform back to the element
    panZoom.current.container.current.firstChild.style.transform =
      updatedTransform;
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="map-container" style={mapContainerStyle}>
        <div className="map-icon-list" style={mapIconListStyle}>
          {data &&
            mapIconMetadata &&
            Object.keys(data).map((index) => {
              const beaconId = data[index].serial_alphanumeric;
              return (
                <>
                  {data[index] ? (
                    <div
                      style={{
                        display: "inline-flex",
                        marginBottom: 15,
                      }}
                    >
                      <div>{mapIconStatic(index, beaconId)}</div>
                      <div
                        style={{
                          marginLeft: "14px",
                          color: "#333333",
                          fontSize: 12,
                          fontWeight: 600,
                          alignSelf: "center",
                        }}
                      >
                        {mapIconMetadata[beaconId].name}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </div>
        <div style={zoomPositionStyle}>
          <button
            className="icon-container"
            style={zoomIconContainerStyle}
            onClick={() => {
              onZoomIn();
            }}
          >
            <ZoomInIcon className="download-icon" style={zoomIconStyle} />
          </button>
          <button
            className="icon-container"
            style={zoomIconContainerStyle}
            onClick={onZoomOut}
          >
            <ZoomOutIcon className="download-icon" style={zoomIconStyle} />
          </button>
        </div>

        <div
          className="map-container-inner"
          style={{
            // height: `${scaledHeight}px`,
            height: MAP_CONTAINER_HEIGHT,
            width: "100%" /*`${scaledWidth}px`*/,
          }}
          ref={mapContainerRef}
        >
          <PanZoom ref={panZoom} zoomSpeed={0.1}>
            <div
              id="zoomable-map"
              style={{
                position: "relative",
                // height: `${scaledHeight}px`,
                // width: `${scaledWidth}px`,
                height: MAP_CONTAINER_HEIGHT,
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  // height: `${scaledHeight}px`,
                  // width: `${scaledWidth}px`,
                  height: MAP_CONTAINER_HEIGHT,
                  width: "100%",
                  transformOrigin: "0p 0px 0px",
                }}
              >
                {map ? <img src={map} alt="Map" draggable={false} /> : <></>}
                {/* <FloorplanSvg
                  id={"floorplan"}
                  markers={markers}
                  reference={svgRef}
                  onHeightChange={handleHeightChange}
                  onWidthChange={handleWidthChange}
                  onScaleFactorChange={handleScaleFactorChange}
                  containerRef={mapContainerRef}
                  user={user}
                  handleMapXOffset={handleMapXOffset}
                  handleMapYOffset={handleMapYOffset}
                  currentLocation={currentLocation}
                /> */}
              </div>

              {contactReplayData &&
                contactReplayData.map((_row, index) => {
                  if (lastFrameSteps.current === undefined) {
                    lastFrameSteps.current = index;
                  }

                  return (
                    <>
                      {index <= seekTime ? (
                        <div
                          style={{
                            position: "absolute",
                            left:
                              // scaledWidth -
                              (scaleFactor
                                ? (contactReplayData[index].x * 100) /
                                  scaleFactor
                                : // mapXOffset +
                                  contactReplayData[index].x * 100) + "px",
                            top:
                              scaledHeight -
                              (scaleFactor
                                ? (contactReplayData[index].y * 100) /
                                  scaleFactor
                                : //  + mapYOffset
                                  contactReplayData[index].y * 100) +
                              "px",
                            transition: "all 2s linear",
                          }}
                        >
                          <div>
                            {replayMapIcon(
                              contactReplayData,
                              contactReplayData[0].tagSerial,
                              index
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}

              {caseReplayData &&
                caseReplayData.map((row, index) => {
                  if (lastFrameSteps.current === undefined) {
                    lastFrameSteps.current = index;
                  }

                  return (
                    <>
                      {index <= seekTime ? (
                        <div
                          style={{
                            position: "absolute",
                            left:
                              // scaledWidth -
                              (scaleFactor
                                ? (caseReplayData[index].x * 100) / scaleFactor
                                : // mapXOffset +
                                  caseReplayData[index].x * 100) + "px",
                            top:
                              scaledHeight -
                              (scaleFactor
                                ? (caseReplayData[index].y * 100) / scaleFactor
                                : //  + mapYOffset
                                  caseReplayData[index].y * 100) +
                              "px",
                            transition: "all 2s linear",
                          }}
                        >
                          <div>
                            {replayMapIcon(
                              caseReplayData,
                              caseReplayData[0].tagSerial,
                              index
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}

              {data &&
                !contactReplayData &&
                Object.keys(data).map((index) => {
                  const beaconId = data[index].serial_alphanumeric;

                  return (
                    <>
                      {data[index] ? (
                        <div
                          style={{
                            position: "absolute",
                            left:
                              // scaledWidth -
                              (scaleFactor
                                ? (data[index].x * 100) / scaleFactor
                                : data[index].x * 100) + "px",
                            top:
                              scaledHeight -
                              (scaleFactor
                                ? (data[index].y * 100) / scaleFactor
                                : data[index].y * 100) +
                              "px",
                            transition: "all 2s linear",
                          }}
                        >
                          {mapIconMetadata ? (
                            <HtmlTooltip
                              id={`map-icon-${beaconId}`}
                              leaveDelay={7000}
                              title={
                                <>
                                  <div>
                                    {mapIconMetadata[beaconId] &&
                                      mapIconMetadata[beaconId].name &&
                                      `${mapIconMetadata[beaconId].name}`}
                                  </div>
                                  <div>
                                    {mapIconMetadata[beaconId] &&
                                      mapIconMetadata[beaconId].location &&
                                      `${mapIconMetadata[beaconId].location}`}{" "}
                                  </div>
                                </>
                              }
                            >
                              {mapIcon(index, data[index].serial_alphanumeric)}
                            </HtmlTooltip>
                          ) : (
                            <>
                              {mapIcon(index, data[index].serial_alphanumeric)}
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </div>
          </PanZoom>
        </div>
      </div>
    </div>
  );
}

export default MapTab;
